import { Accordion, AccordionControlProps, Box, Modal, Switch } from '@mantine/core';
import { Bell } from 'phosphor-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CodeHighlight } from '@mantine/code-highlight';
import { useConsoleFavorites } from '@/hooks/useConsoleFavorites';
import useRouterQuery from '@/hooks/useRouterQuery';
import { primaryColors } from '@/constants/colors';
import { Spinner } from 'andromeda';
import { ModuleList } from './ModuleList';
import { useConsoleContext } from './context/context';
import { READ_SNIPPET } from './mockData/datasets';
import { formatWholesaler } from './formatWhosesaler';
import { useStasusesData } from './useStasusesData';
import '@mantine/code-highlight/styles.css';
import { UseCaseData } from './types';
import StyledTooltip from '../utils/styledTooltip';
import SubscriptionModal from './subscriptionModal';

interface ModulesSectionProps {
    countries: string[];
}

function AccordionControl(props: AccordionControlProps) {
    return (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Accordion.Control {...props} />
        </Box>
    );
}

export const ModulesSection: React.FC<ModulesSectionProps> = ({ countries }) => {
    const { data: favorites } = useConsoleFavorites();
    const [openModal, setOpenModal] = useState(false);

    const { router, handleURLParamsChange } = useRouterQuery<'accordionItems'>();
    const [modelState, setModelState] = useState<{ namespace }>();

    const expandedAccordionItems = useConsoleContext(state => state.expandedAccordionItems);
    const setAccordionItems = useConsoleContext(state => state.setAccordionItems);
    const selectedEnvironment = useConsoleContext(state => state.selectedEnvironment);

    useEffect(() => {
        if (!router.isReady) return;
        const { accordionItems } = router.query;
        if (typeof accordionItems === 'string') setAccordionItems(JSON.parse(accordionItems));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.isReady]);

    const {
        data: statusesData,
        isLoading: isStatusesLoading,
        isFetching,
    } = useStasusesData(selectedEnvironment);

    const countriesData = useMemo(() => {
        const extractingFavorites = {};
        const reformingData = statusesData?.reduce(
            (acc: Record<string, Record<string, UseCaseData>>, item) =>
                item.statuses.length === 0
                    ? acc
                    : {
                          ...acc,
                          [item.namespace]: {
                              ...(acc?.[item.namespace] ?? {}),
                              ...item.statuses.reduce((a, b) => {
                                  const key = b.use_case + formatWholesaler(item.namespace);
                                  if (favorites?.value && favorites?.value?.[key]) {
                                      Object.assign(extractingFavorites, {
                                          [key]: { ...b, namespace: item.namespace },
                                      });
                                  }
                                  return {
                                      ...a,
                                      [b.use_case]: { ...b, namespace: item.namespace },
                                  };
                              }, {}),
                          },
                      },
            {},
        );
        return { ...reformingData, Favorites: extractingFavorites };
    }, [favorites?.value, statusesData]);

    const handleAccordChange = useCallback(
        (arr: string[]) => {
            handleURLParamsChange({
                key: 'accordionItems',
                value: arr.length > 0 ? JSON.stringify(arr) : undefined,
            });
            setAccordionItems(arr);
        },
        [handleURLParamsChange, setAccordionItems],
    );

    const countriesSorted = useMemo(() => {
        if (isFetching) return countries;
        const sorted = countries.toSorted((a, b) => a.localeCompare(b));
        sorted.unshift('Favorites');
        return sorted;
    }, [countries, isFetching]);

    return (
        <div>
            <div className="mb-6 flex flex-col items-start justify-between gap-5 md:flex-row md:items-center">
                <h2 className="!text-lg text-primary">Use cases</h2>
                <Switch
                    labelPosition="left"
                    label="Expand All"
                    color={primaryColors.blue}
                    onChange={e => {
                        handleAccordChange(e.currentTarget.checked ? countriesSorted : []);
                    }}
                />
            </div>
            <Accordion
                chevronPosition="left"
                multiple
                value={expandedAccordionItems}
                onChange={handleAccordChange}
                classNames={{
                    control: 'hover:!bg-primary-10 grow',
                }}
                transitionDuration={300}
            >
                {isFetching && <Spinner />}
                {countriesSorted.map(namespace => (
                    <Accordion.Item
                        key={namespace}
                        value={namespace}
                        className="border-t border-primary-30 "
                    >
                        <div className="relative flex items-center">
                            <AccordionControl style={{ width: 'calc(100dvw - 228px)' }}>
                                <div className="flex grow items-center justify-between">
                                    {formatWholesaler(namespace)}
                                </div>
                            </AccordionControl>

                            {namespace !== 'Favorites' && (
                                <StyledTooltip label="Subscribe to Recommendation email">
                                    <button
                                        type="button"
                                        className="absolute right-5 !shrink-0"
                                        aria-label={`subscribe to ${namespace}`}
                                        onClick={() => setModelState({ namespace })}
                                    >
                                        <Bell />
                                    </button>
                                </StyledTooltip>
                            )}
                        </div>
                        <Accordion.Panel className="!p-0" style={{ padding: 0 }}>
                            <ModuleList
                                namespace={namespace}
                                statusesData={countriesData?.[namespace]}
                                isStatusesLoading={isStatusesLoading}
                                favorites={favorites?.value}
                                isOpen={expandedAccordionItems.includes(namespace)}
                            />
                        </Accordion.Panel>
                    </Accordion.Item>
                ))}
            </Accordion>
            <Modal.Root
                opened={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
                size="500px"
                transitionProps={{ transition: 'rotate-left' }}
                centered
            >
                <Modal.Overlay />
                <Modal.Content>
                    <Modal.Header className="border-spacing-3 border-b border-shadow-600">
                        <Modal.Title>API Docs</Modal.Title>
                        <Modal.CloseButton />
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mt-6 text-xs">
                            Arena CPG OS uses the following data ontology to operate. The datasets
                            can be uploaded using Arena integrations python package, which can be
                            installed via pip,{' '}
                            <span className="w-fit rounded-sm bg-shadow-900 px-1">
                                pip install arena-integrations
                            </span>
                            . Example usage:
                            <CodeHighlight
                                className="my-4 !bg-secondary-2"
                                language="python"
                                title="CODE SNIPPET"
                                code={READ_SNIPPET}
                            />
                        </div>
                    </Modal.Body>
                </Modal.Content>
            </Modal.Root>
            <SubscriptionModal
                modelState={modelState}
                onClose={() => {
                    setModelState(undefined);
                }}
                namespaceData={countriesData?.[modelState?.namespace]}
            />
        </div>
    );
};
