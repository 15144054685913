import useSiberiaNonTableQuery from './useSiberiaNonTableQuery';

type InspectUseCase = 'FORGOTTEN_ITEMS' | 'CROSS_SELL_UP_SELL' | 'QUICK_ORDER';

function useInspect<DATATYPE, Params = unknown>({
    params,
    enabled = true,
    namespace = 'PH-NESTLE',
    queryTable = 'metrics_inspect',
    subNamespace,
    method,
    useCase,
}: {
    params?: Params;
    enabled?: boolean;
    queryTable?: string;
    namespace?: string;
    subNamespace?: string;
    useCase: InspectUseCase;
    method?: 'POST' | 'GET';
}) {
    return useSiberiaNonTableQuery<DATATYPE, Params>({
        endpoint: `inspect/v2/query_table/${queryTable}/namespace/${namespace}/use_case/${useCase}${
            subNamespace ? `/${subNamespace}` : ''
        }`,
        params,
        enabled: enabled && namespace && !!queryTable && !!useCase,
        method,
    });
}

export default useInspect;
export type { InspectUseCase };
