import React from 'react';
import { startCase } from 'lodash';
import { Panel } from './Panel';
import { PanelInfoRow } from './PanelInfoRow';
import { StatusIcon } from '../ModuleCard/StatusIcon';
import { useConsoleContext } from '../context/context';
import { DateFormatter } from '../../../utils/DateFormatter';

export const RecsPostedPanel = () => {
    const selectedPanel = useConsoleContext(state => state.selectedPanel);

    return (
        <Panel variant="recs-posted">
            <div className="space-y-6">
                <PanelInfoRow
                    label="Status"
                    value={
                        <div className="flex items-center gap-2">
                            <StatusIcon status={selectedPanel?.status?.toLowerCase()} />
                            {startCase(selectedPanel?.status?.toLowerCase())}
                        </div>
                    }
                />

                {selectedPanel?.timestamp && (
                    <PanelInfoRow
                        label="Last successful timestamp"
                        value={DateFormatter.formatDate(selectedPanel?.timestamp)}
                    />
                )}

                <PanelInfoRow
                    label="Last successful File Path"
                    value={selectedPanel?.path_to_recommendations}
                />
                {selectedPanel?.failed_timestamp && (
                    <PanelInfoRow
                        label="Failed Timestamp"
                        value={DateFormatter.formatDate(selectedPanel?.failed_timestamp)}
                    />
                )}
                <PanelInfoRow
                    label="Failed Path"
                    value={selectedPanel?.failed_path_to_recommendations}
                />
                {selectedPanel?.message?.description && (
                    <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: selectedPanel?.message?.description?.replace(
                                /background-color/g,
                                'colo',
                            ),
                        }}
                    />
                )}
            </div>
        </Panel>
    );
};
