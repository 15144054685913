import { CaretDoubleRight } from 'phosphor-react';
import React from 'react';
import { useConsoleContext } from '../context/context';
import { CONSOLE_MODULE_NAME_MAP } from '../ConsoleModule';
import { formatWholesaler } from '../formatWhosesaler';

interface PanelHeaderProps {
    close: () => void;
}

export const PanelHeader = ({ close }: PanelHeaderProps) => {
    const selectedPanel = useConsoleContext(state => state.selectedPanel);

    const { module, namespace } = selectedPanel ?? {};

    return (
        <div className="flex items-center justify-between">
            <div>
                <div className="ml-[-31px] flex items-center gap-2 text-gray-400">
                    <button
                        type="button"
                        className="p-1 !pl-0 outline-none hover:text-sky-600"
                        onClick={close}
                        aria-label="close"
                    >
                        <CaretDoubleRight size={18} />
                    </button>
                    <span className="text-sm font-semibold">{formatWholesaler(namespace)}</span>
                </div>
                <div className="flex w-[200%] flex-row items-center justify-between">
                    <div className="mt-1 text-2xl capitalize">
                        {CONSOLE_MODULE_NAME_MAP[module]}
                    </div>
                    {/*  <Button
                        variant="outline"
                        color="gray"
                        size="xs"
                        onClick={() => router.push('/results')}
                    >
                        View details
                    </Button> */}
                </div>
                <div className="mt-3 text-xs text-gray-400">View status details</div>
            </div>
        </div>
    );
};
