import { IconProps } from 'phosphor-react';
import React from 'react';
import StyledTooltip from '../../utils/styledTooltip';

export interface ActionProps {
    label: string;
    onClick: () => void;
    icon: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
    disabled?: boolean;
}

export const Action = ({ label, icon: Icon, onClick, disabled = false }: ActionProps) => (
    <StyledTooltip label={label} position="bottom" withArrow>
        {disabled ? (
            <span className="cursor-not-allowed p-1 text-gray-600">
                <Icon size={24} />
            </span>
        ) : (
            <span className="cursor-pointer p-1">
                <Icon onClick={onClick} size={24} />
            </span>
        )}
    </StyledTooltip>
);
