import { AUTH_API_HOST } from 'buildConfig/processEnv';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAppContext } from '@/contexts/appContext';

const url = new URL(`${AUTH_API_HOST}/auth-service/api/auth/settings/console_favorites/`);
type ConsoleFavorites = Record<string, boolean>;
type ConsoleFavoritesReturned = {
    key: 'CONSOLE_FAVORITES';
    user: number;
    value: ConsoleFavorites;
};
const request = async (method: 'POST' | 'GET', token: string, params?: any) => {
    const response = await fetch(url.toString(), {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },

        method,
        credentials: 'include',
        body: params ? JSON.stringify(params) : undefined,
    });

    if (!response.ok) {
        const res = await response
            .clone()
            .text()
            .catch(() => {
                throw new Error('Failed to parse response');
            });
        const res2 = await response
            .clone()
            .json()
            .catch(() => {
                throw new Error('Failed to parse response');
            });
        return Promise.reject(new Error(res2?.details ?? res));
    }

    const results = await response.json();
    return results;
};

const useConsoleFavorites = () => {
    const { user } = useAppContext();
    const mutationFn = async ({ body }: { body?: Record<string, boolean> }) =>
        typeof body === 'object' &&
        request('POST', user?.siberiaToken, {
            key: 'console_favorites',
            user: user?.id && Number(user?.id),
            value: body,
        });
    const fetchFn = async () => request('GET', user?.siberiaToken);

    const query = useQuery<ConsoleFavoritesReturned>({
        queryFn: fetchFn,
        queryKey: [url],
        enabled: !!user?.siberiaToken,
    });
    const mutation = useMutation({
        mutationFn,
        onSuccess() {
            query.refetch();
        },
    });
    return { ...mutation, ...query };
};

export { useConsoleFavorites };
export type { ConsoleFavorites };
