import { uniqBy } from 'lodash';
import { GlobeHemisphereWest } from 'phosphor-react';
import { useCallback, useEffect, useMemo } from 'react';
import { Skeleton } from '@mantine/core';
import Select from 'andromeda/selects/select';
import { SelectItem } from 'andromeda/types/select/common';
import { countries } from 'countries-list';
import useRouterQuery from '@/hooks/useRouterQuery';
import { ModulesSection } from './ModulesSection';
import { useConsoleContext } from './context/context';
import { useStasusesData } from './useStasusesData';
import {
    COUNTRIES_MAPPED_TO_COUNTRY_LIST,
    COUNTRY_CODE_NAME_MAP,
} from '../../types/hooks/useTableQuery';
import { RecsPostedPanel } from './Panels/RecsPostedPanel';
import { RecsReceivedPanel } from './Panels/RecsReceivedPanel';
import { SpaceportCommonHeader } from '../utils/SpaceportCommonHeader';
import { useUserAccess } from '../../hooks/useUserAccess';
import { useUserAllowedNamespaces } from '../../hooks/useUserAllowedNamespaces';
import { ConsoleData } from './types';

const firstInSorting = {
    World: 'AAAAAAA',
};

const ActionsElement = ({ data, loading }: { data: ConsoleData[]; loading: boolean }) => {
    const { router, handleURLParamsChange } = useRouterQuery<'country'>();
    const selectedNamespace = useConsoleContext(state => state.selectedNamespace);
    const updateSelectedNamespace = useConsoleContext(state => state.updateSelectedNamespace);
    const selectedMetric = useConsoleContext(state => state.selectedMetric);
    // const setSelectedMetric = useConsoleContext(state => state.setSelectedMetric);
    const isArenaStaff = useUserAccess({ userGroupWhitelist: 'arena-staff' });

    const namespacesUserAllowed = useUserAllowedNamespaces();

    useEffect(() => {
        if (!router.isReady)
            if (typeof router.query.country === 'string')
                updateSelectedNamespace(router.query.country);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.isReady]);

    const metricsData = useMemo(
        () =>
            data?.reduce((acc: Record<string, Record<string, SelectItem>>, item) => {
                if (
                    (!isArenaStaff && !namespacesUserAllowed.includes(item.namespace)) ||
                    item.statuses.length === 0
                )
                    return acc;

                const [country, ...metric] = item.namespace.split(/-/);
                const metricName = metric.join(' ') || 'Others';
                return {
                    ...acc,
                    All: {
                        ...(acc?.['All'] ?? {}),
                        World: {
                            label: 'World',
                            value: 'World',
                        },
                        [country]: {
                            label:
                                COUNTRY_CODE_NAME_MAP?.[country] ??
                                countries?.[COUNTRIES_MAPPED_TO_COUNTRY_LIST?.[country] ?? country]
                                    ?.name ??
                                country,
                            value: country,
                        },
                    },
                    [metricName]: {
                        ...(acc?.[metricName] ?? {}),
                        World: {
                            label: 'World',
                            value: 'World',
                        },
                        [country]: {
                            label:
                                COUNTRY_CODE_NAME_MAP?.[country] ??
                                countries?.[COUNTRIES_MAPPED_TO_COUNTRY_LIST?.[country] ?? country]
                                    ?.name ??
                                country,
                            value: country,
                        },
                    },
                };
            }, {}),
        [data, isArenaStaff, namespacesUserAllowed],
    );

    const onNamespaceChange = useCallback(
        e => {
            handleURLParamsChange({ key: 'country', value: e });
            updateSelectedNamespace(e);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [updateSelectedNamespace],
    );

    const namespaceOptions = useMemo(
        () =>
            Object.values(metricsData?.[selectedMetric] ?? {}).sort((a, b) =>
                (firstInSorting?.[a.label] ?? a.label).localeCompare(
                    firstInSorting?.[b.label] ?? b.label,
                ),
            ),
        [metricsData, selectedMetric],
    );

    if (loading) return <Skeleton h={38} w={156} />;
    return (
        <div className="flex items-center gap-2">
            {/* <Select
                data={Object.keys(metricsData)}
                value={selectedMetric}
                onChange={v => {
                    updateSelectedNamespace('World');
                    setSelectedMetric(v);
                }}
                cleanDesign
                label="Metrics"
                width={130}
            /> */}
            <Select
                data={isArenaStaff ? namespaceOptions : namespaceOptions}
                value={selectedNamespace}
                onChange={onNamespaceChange}
                cleanDesign
                label={<GlobeHemisphereWest className="text-blue-600" size={24} />}
                width={130}
            />
        </div>
    );
};

export default function ConsoleHome() {
    const selectedEnvironment = useConsoleContext(state => state.selectedEnvironment);
    const selectedNamespace = useConsoleContext(state => state.selectedNamespace);
    const isArenaStaff = useUserAccess({ userGroupWhitelist: 'arena-staff' });
    const selectedMetric = useConsoleContext(state => state.selectedMetric);

    const { data: statusesData, isLoading } = useStasusesData(selectedEnvironment);

    const namespacesUserAllowed = useUserAllowedNamespaces({ selectedNamespace });
    const uniqueCountries = uniqBy(
        statusesData?.filter(item => item.statuses.length > 0),
        'namespace',
    ).map(item => item.namespace);
    const filteredCountries = uniqueCountries.filter(country => {
        if (selectedNamespace === 'World' && selectedMetric === 'Others' && country.includes('-'))
            return false;
        if (
            selectedMetric !== 'All' &&
            selectedMetric !== 'Others' &&
            !country.endsWith(`${selectedMetric.split(' ').join('-')}`)
        )
            return false;
        if (selectedNamespace !== 'World' && !country.startsWith(selectedNamespace)) return false;
        return namespacesUserAllowed.includes(`${country}`) || isArenaStaff;
    });

    return (
        <>
            <SpaceportCommonHeader
                title="Console"
                description="Validate recommendations posting to the BEES Microservice."
                actionsElement={<ActionsElement data={statusesData} loading={isLoading} />}
            />
            <ModulesSection countries={filteredCountries} />
            <RecsPostedPanel />
            <RecsReceivedPanel />
        </>
    );
}
