import ResponsiveLayout from '@/responsive/ResponsiveLayout';
import { UserGuard } from '@/components/utils/UserGuard';
import { CONSOLE_USER_GROUP_WHITELIST } from '@/components/navBar/constants';
import Console from '../components/console/consoleHome';
import { ConsoleContextProvider } from '../components/console/context/context';

export default function ConsolePage() {
    return (
        <ResponsiveLayout title="Console">
            <UserGuard userGroupWhitelist={CONSOLE_USER_GROUP_WHITELIST}>
                <ConsoleContextProvider>
                    <Console />
                </ConsoleContextProvider>
            </UserGuard>
        </ResponsiveLayout>
    );
}
