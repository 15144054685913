import Text from 'andromeda/text';
import { ConsoleFavorites, useConsoleFavorites } from '@/hooks/useConsoleFavorites';
import FavoriteBtn from '@/components/pricingIntelligence/search/favoriteBtn';
import { CONSOLE_MODULE_NAME_MAP, ConsoleModule } from '../ConsoleModule';
import { StatusIcon } from './StatusIcon';
import { Status } from '../Status';

interface ModuleCardHeaderProps {
    module: ConsoleModule;
    namespace: string;
    status: Status;
    favorites: ConsoleFavorites;
}

export const ModuleCardHeader = ({
    namespace,
    module,
    status,
    favorites = {},
}: ModuleCardHeaderProps) => {
    const { mutate, isPending } = useConsoleFavorites();
    const key = module + namespace;
    return (
        <div className="flex justify-between gap-1">
            <div>
                <div className="flex items-center gap-1">
                    <StatusIcon status={status?.toLowerCase()} />
                    <Text type="14Reg" className="!text-primary">
                        {CONSOLE_MODULE_NAME_MAP[module]}
                    </Text>
                </div>
                <div className="ml-5 mt-1 text-xxs font-semibold text-navy-solid-70">
                    {namespace}
                </div>
            </div>
            <FavoriteBtn
                onClick={() => {
                    mutate({
                        body: { ...favorites, [key]: !favorites?.[key] },
                    });
                }}
                isFavorite={favorites?.[key]}
                isMutating={isPending}
            />
        </div>
    );
};
