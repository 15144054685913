import { useMutation } from '@tanstack/react-query';
import { notification } from '@/utils/notification';
import { Check, X } from 'phosphor-react';
import colors from '@/constants/colors';
import { useAppContext } from '@/contexts/appContext';
import {
    ConsoleSubscribe,
    ConsoleSubscribed,
    ConsoleSubscribedRequested,
    SubscribeAlertNameType,
} from './types';
import { ConsoleApiPaths } from './experiments/ApiPaths';
import useApiQuery from '../../hooks/useApiQuery';

const request = async <T = Record<string, unknown>,>({
    url,
    body,
    token,
}: {
    url: string;
    body: T;
    token: string;
}) => {
    const response = await fetch(url.toString(), {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(body),
    });

    if (response.status >= 400) {
        return Promise.reject(response);
    }

    const results = await response.json();
    return results;
};

const useCommon = <T = Record<string, unknown>,>({
    mutationKey,
    refetch,
    onSuccess,
}: {
    mutationKey: string[];
    refetch?: () => void;
    onSuccess?: () => void;
}) => {
    const { user } = useAppContext();

    return useMutation({
        mutationKey,
        mutationFn: ({ body, url }: { url: string; body: T }) =>
            request<T>({
                url,
                token: user?.siberiaToken,
                body,
            }),
        onError(e, a) {
            notification({
                message: `Failed`,
                icon: <X color={colors.red} size={14} />,
            });
        },
        onSuccess(e, a) {
            refetch?.();
            notification({
                message: `Successful`,
                icon: <Check color={colors.green} size={14} />,
            });
            onSuccess?.();
        },
    });
};

export const useSubscriptionList = ({
    alertName,
    namespace,
}: {
    alertName: SubscribeAlertNameType;
    namespace?: string;
}) => {
    const { data, ...rest } = useApiQuery<ConsoleSubscribed[]>({
        url: ConsoleApiPaths.subscriptionsList({ namespace, alert_name: alertName }),
        method: 'GET',
        disabled: !alertName,
    });

    return {
        data,
        ...rest,
    };
};
export const useMetricNames = () => {
    const { data, ...rest } = useApiQuery<string[]>({
        url: ConsoleApiPaths.metricNames,
        method: 'GET',
    });

    return {
        data,
        ...rest,
    };
};

export const useSubscriptionListRequested = ({
    alertName,
    namespace,
}: {
    alertName: SubscribeAlertNameType;
    namespace: string;
}) => {
    const { data, ...rest } = useApiQuery<ConsoleSubscribedRequested[]>({
        url: ConsoleApiPaths.subscriptionsListRequests({ namespace, alert_name: alertName }),
        method: 'GET',
        disabled: !alertName,
    });

    return {
        data,
        ...rest,
    };
};

export const useSubscription = ({
    refetch,
    onSuccess,
}: {
    refetch: () => void;
    onSuccess: () => void;
}) => {
    const { mutate, ...rest } = useCommon({
        mutationKey: [ConsoleApiPaths.subscribe],
        refetch,
        onSuccess,
    });
    return {
        mutate: (body: ConsoleSubscribe) =>
            mutate({
                body,
                url: ConsoleApiPaths.subscribe,
            }),

        ...rest,
    };
};

export const useUnSubscription = ({
    refetch,
    onSuccess,
}: {
    refetch: () => void;
    onSuccess: () => void;
}) => {
    const { mutate, ...rest } = useCommon({
        mutationKey: [ConsoleApiPaths.unsubscribe],
        refetch,
        onSuccess,
    });
    return {
        mutate: (body: ConsoleSubscribe) =>
            mutate({
                body,
                url: ConsoleApiPaths.unsubscribe,
            }),

        ...rest,
    };
};
