import React from 'react';
import { CaretRight } from 'phosphor-react';
import { Status } from '../Status';
import { StatusIcon } from './StatusIcon';
import StyledTooltip from '../../utils/styledTooltip';
import { RecommendationStatus } from '../types';

interface StatusLineProps {
    status: Status;
    text: string;
    onClick?: () => void;
    message: RecommendationStatus['message'];
}

export const StatusLine: React.FC<StatusLineProps> = ({ status, text, onClick, message }) => (
    <button
        type="button"
        className="ml-[3px] grid w-full grid-cols-12 items-center gap-2 text-left text-primary hover:text-primary-80 disabled:cursor-default"
        onClick={onClick}
        disabled={!onClick}
    >
        <StatusIcon
            status={status?.toLowerCase()}
            className="col-span-1 place-self-center"
            size={10}
        />
        <StyledTooltip
            label="Click to view failure details"
            disabled={!message?.description}
            position="bottom"
            w={250}
        >
            <div className="col-span-11 flex items-center justify-between gap-1 text-xxs">
                <span className="w-fit">{text}</span>
                <CaretRight size={15} />
            </div>
        </StyledTooltip>
    </button>
);
