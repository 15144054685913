import useInspect, { InspectUseCase } from './useInspect';

type PHNestleInspectDetailsTableDataType = {
    description: string;
    display_value: string;
    table_name: string;
    group_by_options: {
        column_name: string;
        description: string;
        display_value: string;
    }[];
    output_options: {
        name: string;
        display_value: string;
        description: string;
    }[];
    where_options: {
        column_name: string;
        display_value: string;
        description: string;
        values: string[];
    }[];
    date_options: {
        from_date: string;
        to_date: string;
    };
};

function useInspectNamespaceDataDescribeTable({
    useCase,
    namespace,
    enabled,
}: {
    useCase: InspectUseCase;
    namespace: string;
    enabled?: boolean;
}) {
    return useInspect<PHNestleInspectDetailsTableDataType>({
        namespace,
        queryTable: 'metrics_inspect',
        method: 'GET',
        subNamespace: 'describe_table',
        useCase,
        enabled,
    });
}

export default useInspectNamespaceDataDescribeTable;
export type { PHNestleInspectDetailsTableDataType };
