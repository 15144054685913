import useInspect, { InspectUseCase } from './useInspect';

type PHNestleInspectDataType = {
    num_influenced_orders: number;
    date?: string;
    acc_cat_1?: string;
    acc_cat_2?: string;
    acc_cat_3?: string;
    acc_cat_4?: string;
    precision?: number;
    orders_influenced?: number;
    recall?: number;
    mape?: number;
    num_stores_ordered?: number;
    num_orders?: number;
    gross_revenue?: number;
    avg_range_purchased: number;
    avg_range_recommended: number;
}[];

type Params = {
    group_by_options: string[];
    output_options: string[];
    where_options: {
        column_name: string;
        values: string[];
    }[];
    date_options: {
        from_date: string;
        to_date: string;
    };
};

function useInspectNamespaceData({
    params,
    enabled,
    useCase,
    namespace,
}: {
    params: Partial<Params>;
    enabled: boolean;
    useCase: InspectUseCase;
    namespace: string;
}) {
    return useInspect<PHNestleInspectDataType, Partial<Params>>({
        namespace,
        queryTable: 'metrics_inspect',
        params,
        method: 'POST',
        enabled,
        useCase,
    });
}

export default useInspectNamespaceData;
export type { PHNestleInspectDataType, Params as PHNestleInspectParamsType };
