import React, { PropsWithChildren } from 'react';
import { Divider, Drawer } from '@mantine/core';
import { useConsoleContext } from '../context/context';
import { PanelHeader } from './PanelHeader';
import { PanelVariant } from '../context/state';

interface PanelProps {
    variant: PanelVariant;
}

export const Panel = ({ variant, children }: PropsWithChildren<PanelProps>) => {
    const selectedPanel = useConsoleContext(state => state.selectedPanel);
    const closePanels = useConsoleContext(state => state.closePanels);

    const { variant: selectedVariant } = selectedPanel ?? {};

    return (
        <Drawer
            opened={variant === selectedVariant}
            onClose={closePanels}
            position="right"
            size="lg"
            withCloseButton={false}
            styles={{ body: { height: '100%' } }}
            transitionProps={{ transition: 'slide-left' }}
        >
            <div className="flex h-full flex-col p-10">
                <PanelHeader close={closePanels} />
                <Divider className="my-6" />
                <div className="grow">{children}</div>
            </div>
        </Drawer>
    );
};
