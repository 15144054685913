import Color from 'color';
import { useCallback, useRef } from 'react';
import { colorScales } from '../constants/colors';

export const bennuBarColor = '#345dee';
export const totalBarColor = '#8656d6';

export const defaultColorsSet =
    // [
    //     '#00A08F',
    //     '#F0C259',
    //     '#DE7159',
    //     '#DB3966',
    //     '#E02F93',
    //     '#FF97ED',
    //     '#AD82FF',
    //     '#77B4FF',
    //     '#83E69B',
    //     '#FBE7A6',
    //     '#E6E6E6',
    //     '#80D0C7',
    //     '#F8E1AC',
    //     '#EFB8AC',
    //     '#ED9CB3',
    //     '#F894DD',
    //     '#FFCBF6',
    //     '#D6C1FF',
    //     '#BBDAFF',
    //     '#C1F3CD',
    //     '#FDF3D3',
    //     '#F3F3F3',
    // ];

    [
        '#eba338',
        '#ed974a',
        '#ed8b59',
        '#ec7f67',
        '#e97475',
        '#e36982',
        '#db6091',
        '#d059a0',
        '#c054b0',
        '#a953c2',
        bennuBarColor,
        totalBarColor,
    ];

export const colorScaleDefaultSet = [
    colorScales.blue[0],
    colorScales.pink[0],
    colorScales.reddishPink[0],
    colorScales.orange[0],
    colorScales.yellow[0],
    colorScales.greenYellow[0],
    colorScales.green[0],
    colorScales.darkGreen[0],
    colorScales.teal[0],
];
const colorScaleSet2 = [
    colorScales.blue[10],
    colorScales.pink[10],
    colorScales.reddishPink[10],
    colorScales.orange[10],
    colorScales.yellow[10],
    colorScales.greenYellow[10],
    colorScales.green[10],
    colorScales.darkGreen[10],
    colorScales.teal[10],
];
const colorScaleSet3 = [
    colorScales.blue[20],
    colorScales.pink[20],
    colorScales.reddishPink[20],
    colorScales.orange[20],
    colorScales.yellow[20],
    colorScales.greenYellow[20],
    colorScales.green[20],
    colorScales.darkGreen[20],
    colorScales.teal[20],
];
const colorScaleSet4 = [
    colorScales.blue[30],
    colorScales.pink[30],
    colorScales.reddishPink[30],
    colorScales.orange[30],
    colorScales.yellow[30],
    colorScales.greenYellow[30],
    colorScales.green[30],
    colorScales.darkGreen[30],
    colorScales.teal[30],
];

type DefinedSets =
    | 'Color Scale'
    | 'Color Scale 10% Darker'
    | 'Color Scale 20% Darker'
    | 'Color Scale 30% Darker';

const colorSets: Record<DefinedSets, string[]> = {
    'Color Scale': colorScaleDefaultSet,
    'Color Scale 10% Darker': colorScaleSet2,
    'Color Scale 20% Darker': colorScaleSet3,
    'Color Scale 30% Darker': colorScaleSet4,
};

function useColors({
    overrideColorsSet,
    defaultColorSets,
}: { overrideColorsSet?: string[]; defaultColorSets?: DefinedSets } = {}) {
    const colorSet = overrideColorsSet || colorSets?.[defaultColorSets] || defaultColorsSet;
    const blackListColors = useRef([]);
    const currentColors = useRef(colorSet);

    const getColor = useCallback((colorIndex: number) => {
        const generateColor = () => {
            const col = `#${Math.random().toString(16).substr(-6)}`;
            if ([...currentColors.current, ...blackListColors.current].indexOf(col) > -1) {
                return generateColor();
            }
            currentColors.current.push(col);

            return col;
        };
        const textColor =
            colorIndex > currentColors.current.length - 1
                ? generateColor()
                : currentColors.current[colorIndex];

        return Color(textColor).rgb();
    }, []);

    return { getColor, colorSet: currentColors.current };
}

export { useColors };
