import useInspectNamespaceData from '@/hooks/useInspectNamespaceData';
import { fusionMoment } from '@/utils/dateHelpers';
import Text from 'andromeda/text';
import { formatNumber } from '@/utils/numberFormatHelpers';
import useInspectNamespaceDataDescribeTable from '@/hooks/useInspectNamespaceDescribeTable';
import { BareCard } from 'andromeda/Card';
import { useMemo } from 'react';
import { ArrowRight } from 'phosphor-react';
import { useRouter } from 'next/router';
import { Skeleton } from '@mantine/core';
import { ConsoleFavorites } from '@/hooks/useConsoleFavorites';
import { ModuleCard } from './ModuleCard/ModuleCard';
import { insightsModulesNotPercentageCards } from '../inspect/graph';
import { UseCaseData } from './types';
import { MODULES_ORDER } from './ConsoleModule';

interface ModuleListProps {
    namespace: string;
    isOpen: boolean;
    favorites: ConsoleFavorites;
    statusesData: Record<string, UseCaseData>;
    isStatusesLoading: boolean;
}

export const ModuleList = ({
    namespace,
    isOpen,
    favorites,
    statusesData,
    isStatusesLoading,
}: ModuleListProps) => {
    const router = useRouter();

    const { statusesOrder, statusesOrderListLength } = {
        statusesOrder: MODULES_ORDER,
        statusesOrderListLength: Object.keys(MODULES_ORDER).length,
    };

    const { data: detailsData, isLoading: isDetailsLoading } = useInspectNamespaceDataDescribeTable(
        {
            useCase: 'QUICK_ORDER',
            namespace,
            enabled: isOpen && namespace !== 'Favorites',
        },
    );

    const mappingOutputKeys = useMemo(
        () =>
            detailsData?.output_options?.reduce(
                (acc, item) => ({
                    ...acc,
                    [item.name]: item.display_value,
                }),
                {},
            ) ?? {},
        [detailsData?.output_options],
    );

    const { data: cards, isLoading: cardsLoading } = useInspectNamespaceData({
        enabled:
            !!statusesData?.['QUICK_ORDER'] &&
            !isStatusesLoading &&
            isOpen &&
            namespace !== 'Favorites',
        params: {
            output_options: ['precision', 'recall', 'mape'],
            date_options: {
                from_date: fusionMoment()
                    .subtract(1, 'day')
                    .subtract(6, 'day')
                    .format('YYYY-MM-DD'),
                to_date: fusionMoment().subtract(1, 'day').format('YYYY-MM-DD'),
            },
        },
        useCase: 'QUICK_ORDER',
        namespace,
    });

    return (
        <div className="flex flex-col gap-3 overflow-auto">
            <div className="flex flex-col gap-3 overflow-auto pb-5 pr-5 md:flex-row">
                {Object.entries(statusesData ?? {})
                    .sort(
                        ([a], [b]) =>
                            (statusesOrder?.[a] ?? statusesOrderListLength) -
                            (statusesOrder?.[b] ?? statusesOrderListLength),
                    )
                    .map(([module, moduleData]) => (
                        <ModuleCard
                            key={module}
                            module={moduleData.use_case}
                            namespace={moduleData?.namespace ?? namespace}
                            recsQueiredStatus={moduleData?.recs_queried_status ?? []}
                            recsReceivedStatus={moduleData?.recs_received_status ?? []}
                            favorites={favorites}
                        />
                    ))}
            </div>
            {!(isDetailsLoading || cardsLoading) && cards?.[0] && (
                <Text type="allCaps/small" className="!text-navy-solid-50">
                    model health - Suggested order (last 7 days)
                </Text>
            )}
            <div className="flex flex-col gap-3 overflow-auto pb-5 pr-5 md:flex-row">
                {isDetailsLoading || cardsLoading ? (
                    <Skeleton className="min-h-[114.5px] min-w-[156px] max-w-[40dvw]" />
                ) : (
                    Object.entries(cards?.[0] ?? {}).map((item, i) => (
                        <BareCard
                            className="flex w-[180px] max-w-fit grow flex-col gap-2 rounded !p-2"
                            key={item[0]}
                        >
                            <div className="flex w-[156px] flex-col items-start justify-start gap-2">
                                <div className="flex w-full items-center justify-between">
                                    <Text
                                        type="14Semi"
                                        className="whitespace-nowrap text-left !text-navy-solid-50"
                                    >
                                        {mappingOutputKeys?.[item[0]] ?? item[0]}
                                    </Text>
                                    <button
                                        type="button"
                                        className="!h-min !w-fit !p-0"
                                        onClick={() =>
                                            router.push(
                                                `/insights?usecase=QUICK_ORDER&namespace=${namespace}&tabQ=graph&chartKey=${item[0]}`,
                                            )
                                        }
                                    >
                                        <Text
                                            type="12Reg"
                                            className="flex items-center  whitespace-nowrap text-left !text-navy-solid-30 hover:!text-navy-solid-70"
                                        >
                                            Time series
                                            <ArrowRight className="ml-1 inline-block" size={16} />
                                        </Text>
                                    </button>
                                </div>
                                <Text
                                    type="12Reg"
                                    className="pl-3 !text-[32px] leading-[44px] !text-navy-solid-50"
                                >
                                    {insightsModulesNotPercentageCards.includes(item[0])
                                        ? formatNumber(item[1], '0,0.00')
                                        : formatNumber(item[1], '0.0%')}
                                </Text>
                            </div>
                        </BareCard>
                    ))
                )}
            </div>
        </div>
    );
};
