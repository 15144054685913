import React from 'react';
import classNames from 'classnames';

interface PanelInfoRowProps {
    label: string;
    value?: string | JSX.Element | number;
    className?: string;
}

export const PanelInfoRow = ({ label, value, className }: PanelInfoRowProps) => {
    if (!value) return null;

    return (
        <div className={classNames('space-y-2', className)}>
            <h4 className="text-xs font-extrabold uppercase text-gray-500">{label}</h4>
            <div className="font-semibold">{value}</div>
        </div>
    );
};
