import colors from '../../constants/colors';

export type Status = 'success' | 'warning' | 'error' | 'in-progress' | 'failure' | string; // TODO: Will be updated

export const STATUS_COLOR_MAP: Record<Status, string> = {
    success: colors.green,
    warning: colors.yellow,
    error: colors.red,
    failure: colors.red,
    'in-progress': '',
    failed: colors.red,
};
