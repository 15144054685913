import { TCountryCode } from 'countries-list';

export type CountryCode = TCountryCode | 'WORLD';

export const COUNTRIES_MAPPED_TO_COUNTRY_LIST = {
    UK: 'GB',
    CHN: 'CN',
};
export const COUNTRY_CODE_NAME_MAP: Partial<Record<CountryCode, string>> = {
    WORLD: 'World',
};

export type CountryPartitionCode = 'us' | 'br';
// | 'AR'
// | 'BR'
// | 'CO'
// | 'DO'
// | 'CA'
// | 'HN'
// | 'MX'
// | 'PA'
// | 'PE'
// | 'ZA'
// | 'EC'
// | 'PY'
// | 'SV'
// | 'UY'
// | 'US'
// | 'DE'
// | 'SG'
// | 'GB'
// | 'AU'
// | 'TR'
// | 'IE'
// | 'JP'
// | 'IT'
// | 'FR'
// | 'DK'
// | 'HU'
// | 'ES'
// | 'WORLD';
