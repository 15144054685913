import { IS_PROD } from '../../../buildConfig/processEnv';
import { EnvKeyType } from '../../../constants/envKey';
import { CountryCode } from '../../../types/hooks/useTableQuery';
import { ConsoleModule } from '../ConsoleModule';
import { RecommendationStatus, SubscribeAlertNameType } from '../types';

export type PanelVariant = 'results' | 'recs-received' | 'recs-posted';

type SelectedPanel = {
    variant: PanelVariant;
    namespace: string;
    module: ConsoleModule;
    modelID: string;
} & Omit<RecommendationStatus, 'model_id'>;

export type State = {
    expandedAccordionItems: string[];
    selectedPanel?: SelectedPanel;
    selectedEnvironment: EnvKeyType;
    selectedRegions: CountryCode[];
    selectedNamespace?: string;
    selectedMetric?: string;
    accountName?: SubscribeAlertNameType;
};

export const initialState: State = {
    expandedAccordionItems: [],
    selectedPanel: undefined,
    selectedEnvironment: IS_PROD ? 'PROD' : 'UAT',
    selectedRegions: [],
    selectedNamespace: 'World',
    selectedMetric: 'All',
};

export const createMethods = (state: State) => ({
    setAccountName: (accountName: SubscribeAlertNameType) => ({ ...state, accountName }),
    setAccordionItems: (ids: string[]) => ({ ...state, expandedAccordionItems: ids }),
    closePanels: () => ({ ...state, selectedPanel: undefined }),
    setResultsPanel: (namespace: string, module: ConsoleModule) => ({
        ...state,
        selectedPanel: {
            variant: 'results',
            namespace,
            module,
        },
    }),
    setSelectedEnvironment: (environment: EnvKeyType) => ({
        ...state,
        selectedEnvironment: environment,
    }),
    setSelectedRegions: (regions: CountryCode[]) => ({ ...state, selectedRegions: regions }),
    updateSelectedNamespace: (namespace: string) => ({
        ...state,
        selectedNamespace: namespace,
        expandedAccordionItems: [],
    }),
    setSelectedMetric: (metric: string) => ({
        ...state,
        selectedMetric: metric,
    }),
    setRecsReceivedPanel: ({
        namespace,
        module,
        modelID,
        ...rest
    }: Omit<SelectedPanel, 'variant'>) => ({
        ...state,
        selectedPanel: {
            variant: 'recs-received',
            namespace,
            module,
            modelID,
            ...rest,
        },
    }),
    setRecsPostedPanel: ({
        namespace,
        module,
        modelID,
        message,
        ...rest
    }: Omit<SelectedPanel, 'variant'>) => ({
        ...state,
        selectedPanel: {
            variant: 'recs-posted',
            namespace,
            module,
            modelID,
            ...rest,
            message,
        },
    }),
});

export type Methods = ReturnType<typeof createMethods>;
