import { Circle } from 'phosphor-react';
import React from 'react';
import { Spinner } from 'andromeda';
import { Status, STATUS_COLOR_MAP } from '../Status';

export interface StatusIconProps {
    status: Status;
    size?: number;
    className?: string;
}

export const StatusIcon = ({ status, size, className }: StatusIconProps) => {
    if (
        status?.toLowerCase()?.includes('in-progress') ||
        status?.toLowerCase()?.includes('exporting')
    ) {
        return <Spinner size={14} className="!h-[16px] !w-[16px]" />;
    }

    return (
        <Circle className={className} color={STATUS_COLOR_MAP[status]} weight="fill" size={size} />
    );
};
