import { Star } from 'phosphor-react';
import React from 'react';
import { Spinner } from 'andromeda';
import { colorScales, grayScale } from '../../../constants/colors';

function FavoriteBtn({
    isFavorite,
    onClick,
    isMutating,
}: {
    isFavorite: boolean;
    onClick: () => void;
    isMutating: boolean;
}) {
    return (
        <button type="button" className="h-fit w-5" onClick={onClick}>
            {isMutating ? (
                <Spinner size={20} color={colorScales.yellow[10]} />
            ) : (
                <Star
                    size={20}
                    style={{
                        color: !isFavorite && grayScale[100],
                        fill: !!isFavorite && colorScales.yellow[0],
                    }}
                    weight={(isFavorite && 'fill') || 'thin'}
                />
            )}
        </button>
    );
}

export default FavoriteBtn;
