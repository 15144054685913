import { Divider } from '@mantine/core';
import { Flask, UserList, Wrench } from 'phosphor-react';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { BareCard } from 'andromeda/Card';
import { ConsoleFavorites } from '@/hooks/useConsoleFavorites';
import { ConsoleModule } from '../ConsoleModule';
import { RecommendationStatus } from '../types';
import { Action, ActionProps } from './Action';
import { ModuleCardHeader } from './ModuleCardHeader';
import { StatusLine } from './StatusLine';
import { Status } from '../Status';
import { formatWholesaler } from '../formatWhosesaler';
import { useConsoleContext } from '../context/context';
import { DateFormatter } from '../../../utils/DateFormatter';

const getReceivedStatusText = (status?: Status, modelID?: string, date?: string) => {
    const innerStatus = status?.toLowerCase();
    if (innerStatus?.includes('success')) {
        return `${modelID}: Data was updated on ${DateFormatter.formatDate(date)}.`;
    }
    if (innerStatus?.includes('failure') || innerStatus?.includes('failed')) {
        return `${modelID}: Data failed to be updated on ${DateFormatter.formatDate(date)}.`;
    }
    return '';
};
const getQueiredStatusText = (status?: Status, modelID?: string, date?: string) => {
    const innerStatus = status?.toLowerCase();
    if (innerStatus?.includes('success')) {
        return `${modelID}: Recommendations were posted on ${DateFormatter.formatDate(date)}.`;
    }
    if (innerStatus?.includes('failure') || innerStatus?.includes('failed')) {
        if (date) {
            return `${modelID}: Recommendations failed to post. Last successful post was on ${DateFormatter.formatDate(
                date,
            )}.`;
        }

        return `${modelID}: Recommendations have never been posted for this model.`;
    }
    if (innerStatus?.includes('exporting')) {
        return `${modelID}: Recommendations are currently being posted.`;
    }
    return '';
};

interface ModuleCardProps {
    module: ConsoleModule;
    namespace: string;
    recsQueiredStatus: RecommendationStatus[];
    recsReceivedStatus: RecommendationStatus[];
    favorites: ConsoleFavorites;
}

export const ModuleCard = ({
    module,
    namespace,
    recsQueiredStatus,
    recsReceivedStatus,
    favorites,
}: ModuleCardProps) => {
    const setRecsPostedPanel = useConsoleContext(state => state.setRecsPostedPanel);
    const setRecsReceivedPanel = useConsoleContext(state => state.setRecsReceivedPanel);

    const router = useRouter();
    const params = new URLSearchParams();
    params.set('namespace', namespace);
    params.set('usecase', module);
    const actions: ActionProps[] = [
        {
            label: 'Experiments',
            icon: Flask,
            onClick: () => router.push(`/experiments?currentView=overview&${params.toString()}`),
        },
        {
            label: 'Configurations',
            icon: Wrench,
            onClick: () => router.push(`/configurations?${params.toString()}`),
            disabled: module === 'DEAL_SORT',
        },

        {
            label: 'Personalizations',
            icon: UserList,
            onClick: () => router.push(`/personalizations?${params.toString()}`),
        },
    ];

    const statuses = [...recsQueiredStatus, ...recsReceivedStatus];

    const overallStatus = (() => {
        const worstToBest: Status[] = [
            'failed',
            'failure',
            'error',
            'warning',
            'exporting',
            'in-progress',
            'success',
        ];
        return worstToBest.find(status =>
            statuses.some(({ status: s }) => s?.toLowerCase().includes(status)),
        );
    })();

    return (
        <BareCard
            className={cx('rounded p-4 !pl-3 !pb-2 flex flex-col min-w-[280px] md:max-w-[280px]', {
                'brightness-75': statuses.length === 0,
            })}
        >
            <ModuleCardHeader
                namespace={formatWholesaler(namespace)}
                module={module}
                status={overallStatus}
                favorites={favorites}
            />
            <div className="mt-2 space-y-4 pb-3">
                <div className="text-xs font-black text-navy-solid-70">Received:</div>
                <div className="space-y-2">
                    {recsReceivedStatus?.map(
                        ({ status, model_id, timestamp, message, failed_timestamp, ...rest }) => (
                            <StatusLine
                                key={model_id}
                                status={status}
                                text={getReceivedStatusText(
                                    status,
                                    model_id,
                                    status === 'Failure' ? failed_timestamp : timestamp,
                                )}
                                onClick={() =>
                                    setRecsReceivedPanel({
                                        ...rest,
                                        status,
                                        namespace,
                                        module,
                                        modelID: model_id,
                                        message,
                                        timestamp,
                                        failed_timestamp,
                                    })
                                }
                                message={message}
                            />
                        ),
                    )}
                </div>
                <div className="text-xs font-black text-navy-solid-70">Posted:</div>
                <div className="space-y-2">
                    {recsQueiredStatus?.map(
                        ({ status, model_id, timestamp, failed_timestamp, message, ...rest }) => (
                            <StatusLine
                                key={model_id}
                                status={status}
                                text={getQueiredStatusText(
                                    status,
                                    model_id,
                                    status === 'Failure' ? failed_timestamp : timestamp,
                                )}
                                onClick={() =>
                                    setRecsPostedPanel({
                                        ...rest,
                                        status,
                                        namespace,
                                        module,
                                        modelID: model_id,
                                        message,
                                        timestamp,
                                        failed_timestamp,
                                    })
                                }
                                message={message}
                            />
                        ),
                    )}
                </div>
            </div>
            <Divider className="mt-auto" />
            <div className="flex items-center justify-around pt-3">
                {actions.map(action => (
                    <Action key={action.label} {...action} />
                ))}
            </div>
        </BareCard>
    );
};
