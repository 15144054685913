import _ from 'lodash';
import { countries } from 'countries-list';
import {
    COUNTRIES_MAPPED_TO_COUNTRY_LIST,
    COUNTRY_CODE_NAME_MAP,
} from '../../types/hooks/useTableQuery';

export const formatWholesaler = (wholesaler?: string) => {
    if (!wholesaler) return '';
    const [country, ...suffix] = wholesaler.split('-');

    const formattedSuffix =
        suffix.length > 0 ? ` - ${_.startCase(suffix.join(' ').toLowerCase())}` : '';

    return `${
        COUNTRY_CODE_NAME_MAP?.[country] ??
        countries?.[COUNTRIES_MAPPED_TO_COUNTRY_LIST?.[country] ?? country]?.name ??
        country
    }${formattedSuffix}`;
};
