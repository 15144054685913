import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Skeleton } from '@mantine/core';
import { ChartDataset } from 'chart.js';
import { useBreakpoint } from '@/responsive/hooks/useBreakpoint';
import { InspectUseCase } from '@/hooks/useInspect';
import Text from 'andromeda/text';
import classNames from 'classnames';
import { BareCard } from 'andromeda/Card';
import useRouterQuery from '@/hooks/useRouterQuery';
import NoDataCard from 'andromeda/noDataCard';
import useInspectNamespaceData, {
    PHNestleInspectParamsType,
} from '../../hooks/useInspectNamespaceData';
import LineChart from '../utils/charts/lineChart';
import { DEFAULT_CHART_POINT_RADIUS } from '../../constants/chart';
import { formatNumber } from '../../utils/numberFormatHelpers';
import { fusionMoment } from '../../utils/dateHelpers';
import { PHNestleInspectDetailsTableDataType } from '../../hooks/useInspectNamespaceDescribeTable';
import { useColors } from '../../hooks/useColors';
import StyledTooltip from '../utils/styledTooltip';

const ignoredCards = [
    'num_stores_ordered',
    'num_influenced_orders',
    'num_orders',
    'mae',
    'gross_revenue',
];
const notPercentage = ['avg_range_purchased', 'avg_range_recommended'];

function Graph({
    data,
    mappingOutputKeys,
    fromDate,
    toDate,
    loading,
    whereOptions,
    groupBy,
    useCase,
    namespace,
}: {
    data: PHNestleInspectDetailsTableDataType;
    mappingOutputKeys: Record<string, string>;
    toDate: string;
    fromDate: string;
    loading: boolean;
    whereOptions: PHNestleInspectParamsType['where_options'];
    groupBy: string[];
    useCase: InspectUseCase;
    namespace: string;
}) {
    const { router, handleURLParamsChange } = useRouterQuery();
    const { data: cards, isLoading: cardsLoading } = useInspectNamespaceData({
        enabled: data?.group_by_options?.length > 0,
        params: {
            output_options: data?.output_options?.map(item => item.name),
            date_options: {
                from_date: fromDate,
                to_date: toDate,
            },
            where_options: whereOptions,
        },
        useCase,
        namespace,
    });

    const [key, setKey] = useState('orders_influenced');

    useEffect(() => {
        if (!router.isReady) return;
        const { chartKey } = router.query;
        if (typeof chartKey === 'string') setKey(chartKey);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.isReady]);

    const { data: chart, isLoading: chartDataLoading } = useInspectNamespaceData({
        enabled: data?.group_by_options?.length > 0,
        params: {
            group_by_options: groupBy.concat('date'),
            output_options: data?.output_options?.map(item => item.name),
            date_options: {
                from_date: fromDate,
                to_date: toDate,
            },
            where_options: whereOptions,
        },
        useCase,
        namespace,
    });

    const { colorSet } = useColors({ defaultColorSets: 'Color Scale' });

    const chartData = useMemo(
        () =>
            Object.values(
                chart?.reduce?.(
                    (
                        acc: Record<
                            string,
                            ChartDataset<'line', Record<string, string | number>[]>
                        >,
                        item,
                    ) => {
                        let k = '';
                        if (item?.acc_cat_1) k += `${item?.acc_cat_1}`;
                        if (item?.acc_cat_2) k += `${k.length > 0 ? ' - ' : ''}${item?.acc_cat_2}`;
                        if (item?.acc_cat_3) k += `${k.length > 0 ? ' - ' : ''}${item?.acc_cat_3}`;
                        if (item?.acc_cat_4) k += `${k.length > 0 ? ' - ' : ''}${item?.acc_cat_4}`;

                        const sortedData = [
                            ...(acc?.[k]?.data ?? []),
                            {
                                y: (item?.[key] as number) ?? 0,
                                x: item.date,
                                label:
                                    key === 'orders_influenced'
                                        ? `(${item.num_influenced_orders}/${item.num_orders})`
                                        : undefined,
                            },
                        ].toSorted((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime());
                        return {
                            ...acc,
                            [k]: {
                                data: sortedData,
                                pointBorderColor: 'transparent',
                                borderWidth: 1.5,
                                tension: 0,
                                label: k,
                                pointRadius: DEFAULT_CHART_POINT_RADIUS,
                                borderColor: `${colorSet?.[0]}cc`,
                                backgroundColor: colorSet?.[0],
                                pointStyle: 'rectRounded',
                            },
                        };
                    },
                    {},
                ) ?? {},
            ),
        [chart, colorSet, key],
    );
    const labels = useMemo(
        () =>
            Object.values(
                chart?.reduce?.(
                    (acc: Record<string, string>, item) => ({ ...acc, [item.date]: item.date }),
                    {},
                ) ?? {},
            ).sort((a, b) => new Date(a).getTime() - new Date(b).getTime()),
        [chart],
    );
    // const isMaxDateToday = toDate === fusionMoment().subtract(1, 'day').format('YYYY-MM-DD');
    // const selectedDuration = useMemo<string>(
    //     () =>
    //         (isMaxDateToday && durations.filter(obj => obj.value === fromDate)?.[0]?.label) ||
    //         `Last ${fusionMoment(toDate).diff(fusionMoment(fromDate), 'd')} Days`,
    //     [fromDate, isMaxDateToday, toDate],
    // );

    const min = Math.min(...(chart?.map?.(x => x?.[key]) ?? []));
    const max = Math.max(...(chart?.map?.(x => x?.[key]) ?? []));
    const mq = useBreakpoint();
    const chartStyle = {
        height: !mq.lg ? 530 : undefined,
        maxWidth: mq.lg ? 'calc(100% - 205px)' : '100%',
        minWidth: mq.lg ? 'calc(100% - 205px)' : 'calc(100dvw - 164px)',
        maxHeight: mq.lg ? 'calc(100vh - 207px)' : 'calc(100vh - 310px)',
        minHeight: mq.lg ? 'calc(100vh - 207px)' : 'calc(100vh - 310px)',
    };
    const loadingState = chartDataLoading || loading || cardsLoading;

    return (
        <div
            className={classNames('flex gap-6 flex-col-reverse lg:flex-row', {
                'items-center justify-center min-h-[40dvh]': labels.length === 0,
            })}
            style={{
                maxHeight: mq.lg ? 'calc(100vh - 257px)' : 680,
                minHeight:
                    labels.length === 0
                        ? 'calc(100vh - 257px)'
                        : (loading && 'calc(100vh - 274px)') || 'calc(100vh - 221px)',
            }}
        >
            {loadingState ? (
                <Skeleton style={{ ...chartStyle, height: !mq.lg ? 530 : 'calc(100vh - 217px)' }} />
            ) : (
                (Object.values(chart ?? {}).length === 0 && (
                    <div
                        style={{ width: chartStyle.minWidth }}
                        className="flex items-center justify-center"
                    >
                        <NoDataCard>
                            No data available, please contact abi-ghq-support@arena-ai.com for more
                            information.
                        </NoDataCard>
                    </div>
                )) || (
                    <BareCard
                        className="flex flex-col gap-10 rounded pb-5"
                        style={{
                            ...chartStyle,
                            minHeight: `calc(${chartStyle.minHeight} - 14px)`,
                            maxHeight: `calc(${chartStyle.maxHeight} - 14px)`,
                        }}
                    >
                        <Text type="14Reg" className="!text-navy-solid-70">
                            {mappingOutputKeys?.[key] ?? key}
                        </Text>
                        <LineChart
                            className="pb-10"
                            id="line-chart"
                            data={{
                                labels: labels ?? [],
                                datasets: chartData,
                            }}
                            legend={{
                                display: false,
                            }}
                            yAxis={{
                                display: true,
                                ticks: {
                                    callback(tickValue) {
                                        if (notPercentage?.includes(key))
                                            return formatNumber(tickValue, '0,0.00');
                                        return formatNumber(tickValue, '0.00%');
                                    },
                                },
                                suggestedMin: min * 0.9,
                                suggestedMax: max * 1.05,
                                grid: {
                                    color: 'transparent',
                                },
                            }}
                            xAxis={{
                                grid: {
                                    color: 'transparent',
                                },
                                ticks: {
                                    callback(tickValue) {
                                        return fusionMoment(labels[tickValue]).format(
                                            'MMM DD YYYY',
                                        );
                                    },
                                },
                            }}
                            tooltip={{
                                callbacks: {
                                    label(tooltipItem) {
                                        if (notPercentage?.includes(key)) {
                                            if (chartData.length > 1)
                                                return `${
                                                    tooltipItem.dataset.label
                                                }: ${formatNumber(
                                                    tooltipItem.formattedValue,
                                                    '0,0.00',
                                                )}`;
                                            return formatNumber(
                                                tooltipItem.formattedValue,
                                                '0,0.00',
                                            );
                                        }
                                        if (chartData.length > 1)
                                            return `${tooltipItem.dataset.label}: ${formatNumber(
                                                tooltipItem.formattedValue,
                                                '0.00%',
                                            )}`;
                                        return `${formatNumber(
                                            tooltipItem.formattedValue,
                                            '0.00%',
                                        )} ${
                                            tooltipItem.raw?.['label']
                                                ? tooltipItem.raw['label']
                                                : ''
                                        }`;
                                    },
                                    title(tooltipItems) {
                                        return fusionMoment(tooltipItems?.[0]?.label).format(
                                            'MMM DD YYYY',
                                        );
                                    },
                                },
                            }}
                        />
                    </BareCard>
                )
            )}
            <div
                className="flex flex-wrap gap-4 overflow-auto max-lg:w-full lg:flex-col lg:flex-nowrap"
                style={{ maxHeight: !mq.lg ? 80 : 'calc(100vh - 207px)' }}
            >
                {loadingState ? (
                    <>
                        <Skeleton className="min-h-[77px] max-w-[194px] lg:!w-[180px]" />
                        <Skeleton className="min-h-[77px] max-w-[194px] lg:w-[180px]" />
                        <Skeleton className="min-h-[77px] max-w-[194px] lg:w-[180px]" />
                        <Skeleton className="min-h-[77px] max-w-[194px] lg:w-[180px]" />
                        <Skeleton className="min-h-[77px] max-w-[194px] lg:w-[180px]" />
                        <Skeleton className="min-h-[77px] max-w-[194px] lg:w-[180px]" />
                        <Skeleton className="min-h-[77px] max-w-[194px] lg:w-[180px]" />
                    </>
                ) : (
                    Object.entries(cards?.[0] ?? {}).map(
                        (item, i) =>
                            !ignoredCards.includes(item[0]) && (
                                <BareCard
                                    className="flex max-h-[80px] w-[180px] grow flex-col gap-2 rounded !p-2"
                                    key={item[0]}
                                >
                                    <StyledTooltip label="Click a different metric to view it on the graph">
                                        <button
                                            type="button"
                                            className="flex w-[156px] flex-col items-start justify-start gap-2 opacity-50 disabled:opacity-100"
                                            onClick={() => {
                                                handleURLParamsChange({
                                                    key: 'chartKey',
                                                    value: item[0],
                                                });
                                                setKey(item[0]);
                                            }}
                                            disabled={item[0] === key}
                                        >
                                            <Text
                                                type="12Reg"
                                                className="whitespace-nowrap text-left !text-navy-solid-70"
                                            >
                                                {mappingOutputKeys?.[item[0]] ?? item[0]}
                                            </Text>
                                            <Text
                                                type="12Reg"
                                                className="!text-[32px] leading-[44px] !text-navy-solid-70"
                                            >
                                                {notPercentage.includes(item[0])
                                                    ? formatNumber(item[1], '0,0.00')
                                                    : formatNumber(item[1], '0.0%')}
                                            </Text>
                                        </button>
                                    </StyledTooltip>
                                </BareCard>
                            ),
                    )
                )}
            </div>
        </div>
    );
}

export default Graph;

export { notPercentage as insightsModulesNotPercentageCards };
