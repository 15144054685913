/* eslint-disable react/no-danger */
import React from 'react';
import { Panel } from './Panel';
import { StatusIcon } from '../ModuleCard/StatusIcon';
import { PanelInfoRow } from './PanelInfoRow';
import { useConsoleContext } from '../context/context';
import { DateFormatter } from '../../../utils/DateFormatter';

export const RecsReceivedPanel = () => {
    const selectedPanel = useConsoleContext(state => state.selectedPanel);

    return (
        <Panel variant="recs-received">
            <div className="flex h-full flex-col gap-6">
                <div className="flex items-center gap-2 text-lg">
                    <StatusIcon status={selectedPanel?.status?.toLowerCase()} />
                    {selectedPanel?.modelID}
                </div>

                {selectedPanel?.timestamp && (
                    <PanelInfoRow
                        label="Last successful timestamp"
                        value={DateFormatter.formatDate(selectedPanel?.timestamp)}
                    />
                )}
                <PanelInfoRow label="File Path" value={selectedPanel?.path_to_recommendations} />
                <PanelInfoRow label="# of POCs" value={selectedPanel?.message?.num_pocs} />
                <PanelInfoRow
                    label="# of Recs"
                    value={selectedPanel?.message?.num_recommendations}
                />
                <PanelInfoRow
                    label="# of Distinct Products"
                    value={selectedPanel?.message?.num_distinct_products}
                />

                {/*  <PanelInfoRow
                    label="Questions?"
                    value={
                        <div>
                            Please email:{' '}
                            <a href="mailto:abi-ghq-support@arena-ai.com">
                                abi-ghq-support@arena-ai.com
                            </a>
                        </div>
                    }
                    className="!mt-auto"
                /> */}
                {selectedPanel?.failed_timestamp && (
                    <PanelInfoRow
                        label="Failed Timestamp"
                        value={DateFormatter.formatDate(selectedPanel?.failed_timestamp)}
                    />
                )}
                <PanelInfoRow
                    label="Failed Path"
                    value={selectedPanel?.failed_path_to_recommendations}
                />
                {selectedPanel?.message?.description && (
                    <PanelInfoRow
                        label="Description"
                        value={
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: selectedPanel?.message?.description?.replace(
                                        /background-color/g,
                                        'colo',
                                    ),
                                }}
                            />
                        }
                        className="!mt-auto max-w-full overflow-auto"
                    />
                )}
            </div>
        </Panel>
    );
};
