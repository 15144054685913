import { Input, Modal, Table, Title } from '@mantine/core';
import StyledTable from 'andromeda/styledTable';
import { Equals, Trash, X } from 'phosphor-react';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, Card, Spinner, Text, Toggle } from 'andromeda';
import { useAppContext } from '@/contexts/appContext';
import Select from 'andromeda/selects/select';
import { GreaterThan, GreaterThanOrEqual, LessThan, LessThanOrEqual } from '@phosphor-icons/react';
import colors from '@/constants/colors';
import { formatWholesaler } from './formatWhosesaler';
import { SubscribeAlertNameType, UseCaseData } from './types';
import {
    useMetricNames,
    useSubscription,
    useSubscriptionList,
    useUnSubscription,
} from './useSubscription';
import { formatUseCaseName } from '../utils/UseCaseDropdown';
import StyledTooltip from '../utils/styledTooltip';

const operatorsMapping = {
    GREATER_THAN: <GreaterThan aria-label="GREATER_THAN" />,
    LESS_THAN: <LessThan aria-label="LESS_THAN" />,
    EQUAL_TO: <Equals aria-label="EQUAL_TO" />,
    LESS_THAN_OR_EQUAL_TO: <LessThanOrEqual aria-label="LESS_THAN_OR_EQUAL" />,
    GREATER_THAN_OR_EQUAL_TO: <GreaterThanOrEqual aria-label="GREATER_THAN_OR_EQUAL" />,
};

const operatorOptions = Object.entries(operatorsMapping).map(([value, icon]) => ({
    value,
    icon,
    label: '',
}));

const SubscriptionModal = ({
    modelState,
    onClose,
    namespaceData,
}: {
    modelState: { namespace };
    onClose: () => void;
    namespaceData: Record<string, UseCaseData>;
}) => {
    const { user } = useAppContext();
    const [alertName, setAlertName] = useState<SubscribeAlertNameType>('recommendation_ingestion');
    const [isCreateNew, setIsCreateNew] = useState(false);
    const [metricName, setMetricName] = useState('precision');
    const [useCase, setUseCase] = useState(Object.keys(namespaceData ?? {})?.[0] || 'QUICK_ORDER');
    const [operator, setOperator] = useState('GREATER_THAN');
    const [threshold, setThreshold] = useState(0);

    const selectedNamespaceUseCases = Object.keys(namespaceData ?? {})?.map(item => ({
        value: item,
        label: formatUseCaseName(item),
    }));

    const options = ['Recommendation_Ingestion', 'Metric_Alert'].map(item => ({
        value: item.toLowerCase(),
        label: item.replace(/_/g, ' '),
    }));

    const {
        data: dataSubscriptionList,
        isLoading: isLoadingSubscriptionList,
        refetch,
    } = useSubscriptionList({ alertName, namespace: modelState?.namespace });
    const { data } = useMetricNames();

    const { isPending: isSubscribeLoading, mutate: onSubscribe } = useSubscription({
        refetch,
        onSuccess() {
            setIsCreateNew(false);
        },
    });
    const { isPending: isUnsubscribeLoading, mutate: onUnsubscribe } = useUnSubscription({
        refetch,
        onSuccess() {
            setIsCreateNew(false);
        },
    });

    const isLoading = isSubscribeLoading || isUnsubscribeLoading || isLoadingSubscriptionList;

    const handleClosing = useCallback(() => {
        setIsCreateNew(false);
        onClose();
    }, [onClose]);

    const subscribedTo = useMemo(
        () =>
            dataSubscriptionList?.reduce(
                (acc: Record<string, boolean>, item) => ({
                    ...acc,
                    [item.alert_config?.['namespace']]: true,
                }),
                {},
            ) ?? {},
        [dataSubscriptionList],
    );

    const handleSubscription = useCallback(() => {
        if (alertName === 'metric_alert')
            onSubscribe({
                alert_config: {
                    namespace: modelState?.namespace,
                    use_case: useCase,
                    criteria: {
                        metric_name: metricName.toLowerCase(),
                        operator,
                        threshold,
                    },
                },
                alert_name: alertName,
            });
        else if (!subscribedTo[modelState?.namespace])
            onSubscribe({
                alert_config: {
                    namespace: modelState?.namespace,
                    use_case: useCase,
                },
                alert_name: alertName,
            });
        else
            onUnsubscribe({
                alert_config: {
                    namespace: modelState?.namespace,
                    use_case: useCase,
                },
                alert_name: alertName,
            });
    }, [
        alertName,
        metricName,
        modelState?.namespace,
        onSubscribe,
        onUnsubscribe,
        operator,
        subscribedTo,
        threshold,
        useCase,
    ]);

    const recommendationIngestionBody = useMemo(
        () => [
            <Table.Tr key="user-row">
                <Table.Td className="!text-left !lowercase">{user?.email}</Table.Td>
                <Table.Td>
                    {isLoading ? (
                        <div className="flex min-w-[110px] items-center justify-center">
                            <Spinner size={16} />
                        </div>
                    ) : (
                        <div className="flex w-min items-center justify-end gap-5">
                            <button
                                type="button"
                                className="font-semibold text-inherit"
                                aria-label="Subscribe"
                                onClick={handleSubscription}
                                disabled={isLoading || subscribedTo[modelState?.namespace]}
                            >
                                Subscribe{subscribedTo[modelState?.namespace] && 'd'}
                            </button>
                            {subscribedTo[modelState?.namespace] && (
                                <button
                                    type="button"
                                    className="font-semibold text-inherit"
                                    aria-label="Subscribe"
                                    onClick={() => {
                                        onUnsubscribe({
                                            alert_config: {
                                                namespace: modelState?.namespace,
                                                use_case: useCase,
                                            },
                                            alert_name: alertName,
                                        });
                                    }}
                                    disabled={isLoading}
                                >
                                    <Trash />
                                </button>
                            )}
                        </div>
                    )}
                </Table.Td>
            </Table.Tr>,
        ],
        [
            alertName,
            handleSubscription,
            isLoading,
            modelState?.namespace,
            onUnsubscribe,
            subscribedTo,
            useCase,
            user?.email,
        ],
    );

    const metricAlertBody = useMemo(
        () =>
            dataSubscriptionList?.map(item => (
                <Table.Tr key={item.alert_name + item.alert_config.use_case}>
                    <Table.Td className="!text-center">
                        {formatUseCaseName(item.alert_config.use_case)}
                    </Table.Td>
                    <Table.Td className="!text-left">
                        {item?.alert_config?.criteria?.metric_name}
                    </Table.Td>
                    <Table.Td className="!text-left">
                        {operatorsMapping[item?.alert_config?.criteria?.operator]}
                    </Table.Td>
                    <Table.Td className="!text-left">
                        {item?.alert_config?.criteria?.threshold}
                    </Table.Td>
                    {subscribedTo[modelState?.namespace] && (
                        <Table.Td className="!text-left">
                            <StyledTooltip label="Unsubscribe">
                                <button
                                    type="button"
                                    className="flex items-center justify-center font-semibold text-inherit"
                                    aria-label="Subscribe"
                                    onClick={() => {
                                        onUnsubscribe(item);
                                    }}
                                    disabled={isLoading}
                                >
                                    <Trash />
                                </button>
                            </StyledTooltip>
                        </Table.Td>
                    )}
                </Table.Tr>
            )),
        [dataSubscriptionList, isLoading, modelState?.namespace, onUnsubscribe, subscribedTo],
    );

    return (
        <Modal.Root opened={!!modelState?.namespace} onClose={handleClosing}>
            <Modal.Overlay />
            <Modal.Content className="text-navy-solid-50">
                <Modal.Header className="mx-5 border-spacing-3 flex-col !items-start gap-1 border-b border-border-color !px-0 !py-5">
                    <div className="flex w-full items-center justify-between">
                        <Title order={4} className="!text-navy-solid-50">
                            {isCreateNew
                                ? 'Create New Alert'
                                : `Subscribe to alerts for ${formatWholesaler(modelState?.namespace)}`}
                        </Title>
                        <button
                            type="button"
                            aria-label="close subscription"
                            onClick={handleClosing}
                            className="text-navy-solid-50"
                        >
                            <X size={24} />
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Card
                        innerClassName="h-[194px]"
                        title={
                            <Toggle
                                options={options}
                                onSelectionChange={e => setAlertName(e as SubscribeAlertNameType)}
                                title="Alert Type:"
                                selectedBgColor={colors.arenaBlue}
                                selectedTextColor="#fff"
                                defaultOption={alertName}
                                variant="secondary"
                            />
                        }
                    >
                        {isCreateNew ? (
                            alertName === 'recommendation_ingestion' ? (
                                <Text type="14Light" className="!text-navy-solid-70">
                                    Trigger on daily failures and successes
                                </Text>
                            ) : (
                                <div className="flex flex-wrap items-center space-x-1">
                                    <Text type="14Reg">Trigger when</Text>
                                    <Select
                                        checkIcon
                                        onChange={setMetricName}
                                        className="-mx-2"
                                        cleanDesign
                                        width={200}
                                        data={
                                            data?.map?.(value => ({
                                                value,
                                                label: value.replace(/_/g, ' '),
                                            })) ?? []
                                        }
                                        value={metricName}
                                        classNames={{
                                            input: { input: 'capitalize !pr-5' },
                                            select: { option: 'capitalize' },
                                        }}
                                    />
                                    <Text type="14Reg">for</Text>
                                    <Select
                                        checkIcon
                                        onChange={setUseCase}
                                        className="-mx-2"
                                        cleanDesign
                                        width={190}
                                        data={selectedNamespaceUseCases}
                                        value={useCase}
                                        classNames={{
                                            input: {
                                                input: '!pr-5',
                                            },
                                        }}
                                    />
                                    <Text type="14Reg">is</Text>
                                    <Select
                                        onChange={setOperator}
                                        className="-mx-2"
                                        cleanDesign
                                        width={50}
                                        data={operatorOptions}
                                        value={operator}
                                        classNames={{
                                            input: { input: '!text-base !pr-5' },
                                            select: { option: '!text-base' },
                                        }}
                                    />
                                    <Text type="14Reg">than</Text>
                                    <Input
                                        type="number"
                                        w={60}
                                        min={0}
                                        step={0.0001}
                                        onChange={e => {
                                            const val = e.currentTarget.valueAsNumber;
                                            if (e.currentTarget.validity.valid) {
                                                setThreshold(val);
                                            }
                                        }}
                                        value={threshold}
                                        classNames={{
                                            input: '!border-none !text-navy !p-0 !w-fit',
                                        }}
                                    />
                                    <Text type="14Reg">.</Text>
                                </div>
                            )
                        ) : (
                            <StyledTable
                                maxHeight={190}
                                minHeight={190}
                                loading={isLoadingSubscriptionList}
                                body={
                                    alertName === 'metric_alert'
                                        ? metricAlertBody
                                        : recommendationIngestionBody
                                }
                                justTable
                                dataCondition={
                                    alertName === 'metric_alert' &&
                                    dataSubscriptionList?.length === 0
                                }
                                noDataText={
                                    alertName === 'metric_alert' && 'No Metric Alert was created.'
                                }
                            />
                        )}
                    </Card>
                    {isCreateNew ? (
                        <div className="flex items-end justify-end gap-2">
                            <Button
                                containerClassName="pt-2 ml-auto mr-2"
                                onClick={() => {
                                    setIsCreateNew(false);
                                }}
                                variant="secondary"
                            >
                                Back
                            </Button>
                            <Button
                                containerClassName="pt-2 mr-2"
                                onClick={handleSubscription}
                                loading={isLoading}
                            >
                                Subscribe
                                {alertName === 'recommendation_ingestion' &&
                                    subscribedTo[modelState?.namespace] &&
                                    'd'}
                            </Button>
                        </div>
                    ) : (
                        <Button
                            containerClassName="pt-2 ml-auto mr-2"
                            onClick={() => setIsCreateNew(true)}
                        >
                            Create New
                        </Button>
                    )}
                </Modal.Body>
            </Modal.Content>
        </Modal.Root>
    );
};

export default SubscriptionModal;
