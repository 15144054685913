import { PropsWithChildren, useEffect, useMemo } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { useMethods } from '../../../hooks/useMethods';
import { createMethods, initialState, Methods, State } from './state';

type ConsoleContextType = State & Methods;

const ConsoleContext = createContext<ConsoleContextType>({
    ...initialState,
    ...createMethods(initialState),
});

export const ConsoleContextProvider = ({ children }: PropsWithChildren) => {
    const [state, methods] = useMethods(createMethods as any, initialState);

    const value = useMemo(() => ({ ...state, ...(methods as Methods) }), [state, methods]);

    return <ConsoleContext.Provider value={value}>{children}</ConsoleContext.Provider>;
};

export const useConsoleContext = <T,>(selector: (state: ConsoleContextType) => T) =>
    useContextSelector(ConsoleContext, selector);
