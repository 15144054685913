import { useQuery } from '@tanstack/react-query';
import { SPACEPORT_API_URL } from '../buildConfig/processEnv';
import { useAppContext } from '../contexts/appContext';

// 'inspect/query_table/metrics_inspect/namespace/PH-NESTLE/describe_table'
// 'inspect/query_table/metrics_inspect/namespace/PH-NESTLE'
function useSiberiaNonTableQuery<DATATYPE, Params = unknown>({
    params,
    enabled = true,
    endpoint,
    method,
}: {
    params?: Params;
    enabled: boolean;
    method?: 'POST' | 'GET';
    endpoint: string;
}) {
    const { user } = useAppContext();
    const request = async () => {
        // https://spaceport-api-uat.arena-ai.com/spaceport/api/inspect/query_table/metrics_inspect/namespace/PH-NESTLE/describe_table/
        // https://spaceport-api-uat.arena-ai.com/spaceport/api/inspect/query_table/metrics_inspect/namespace/PH-NESTLE/describe_table
        const url = new URL(`${SPACEPORT_API_URL}/spaceport/api/${endpoint}`);
        const response = await fetch(url.href, {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${user?.siberiaToken}`,
            },
            method,
            credentials: 'include',
            body: method === 'POST' ? JSON.stringify(params) : undefined,
        });

        if (response.status >= 400) {
            return Promise.reject(response);
        }
        const results = await response.json();
        return results;
    };
    const { data, isLoading, ...rest } = useQuery<DATATYPE>({
        queryKey: [endpoint, JSON.stringify(params)],
        queryFn: request,
        enabled: enabled && !!user?.siberiaToken,
    });
    return { data, isLoading: enabled && !!user?.siberiaToken && isLoading, ...rest };
}

export default useSiberiaNonTableQuery;
